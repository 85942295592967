import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export function useSharedState<T>(key: string, initialValue: T) {
	const queryClient = useQueryClient();
	const { data: state } = useQuery(key, () => queryClient.getQueryData(key), {
		initialData: initialValue,
	});

	const setState = useCallback(
		(value: T) => queryClient.setQueryData(key, value),
		[key, queryClient],
	);

	return { state, setState };
}

// Keys
export const IS_SM_SCREEN = 'isSmScreen';
export const IS_MD_SCREEN = 'isMdScreen';
export const CONTENT_TRANSFER = 'contentTransfer';
export const IS_DELETED_ENVIRONMENT = 'isDeletedEnvironmentId';
