import { useState } from 'react';
import { HStack, Stack, Tooltip } from '@chakra-ui/react';
import { useSharedState } from '../../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../../@xmcloud/hooks/context/useSharedState';
import {
	Footer,
	Icon,
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
	XMCSButton,
} from '../../../shared-components';
import { mdiPlus } from '@mdi/js';
import { EnvironmentTypesEnum } from '../../create-project/helpers';
import { useGetEnvironmentsByQuery } from '../../../services/environments';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import { useEnvironmentsTableColumns } from '../columns/environments';
import { ColumnDef } from '@tanstack/react-table';
import { IGetEnvironmentResponseV2 } from '../../../models/environmentModel';
import { Main as CreateEnvironment } from '../../create-edit-environment/Main';
import { useGetEnvType } from '../hooks/useGetEnvType';
import { editCreateEnv } from '@xmcloud/core/messages/en';

const { addCm, addEh, noCmEnvTooltipMsg } = editCreateEnv;

const headers = ['Type', 'Name', 'ID', 'Date created', ''];

const { CM } = EnvironmentTypesEnum;

export const EnvironmentsPage: React.FC = () => {
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);

	const { isCmEnv, isEhEnv, envType, projectId } = useGetEnvType();

	const buttonText = isEhEnv ? addEh : addCm;

	const { data: environments, isLoading: isEnvironmentsLoading } =
		useGetEnvironmentsByQuery({
			type: envType as EnvironmentTypesEnum,
			projectId: projectId!,
		});
	const { data: cmEnvironments, isLoading: isCmEnvironmentsLoading } =
		useGetEnvironmentsByQuery({
			type: CM,
			projectId: projectId!,
			_enabled: isEhEnv,
		});

	const columns: ColumnDef<IGetEnvironmentResponseV2, any>[] =
		useEnvironmentsTableColumns(envType);

	const envLength = environments?.length;
	const cmEnvLength = cmEnvironments?.length || 0;

	const isLoading = isEnvironmentsLoading || isCmEnvironmentsLoading;
	const disableCreateEhBtn = !cmEnvLength && isEhEnv;

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;

	return (
		<Stack>
			<HStack justify="flex-end" py={isSmScreen ? 3 : 5}>
				{!isSmScreen && (
					<CreateButton
						setOpenCreateModal={setOpenCreateModal}
						isDisabled={disableCreateEhBtn}
						buttonText={buttonText}
					/>
				)}
			</HStack>
			{!envLength ? (
				<RenderPlaceholder item="environments" />
			) : (
				<Table
					{...{
						columns: columns,
						tableData: environments,
						dataTestId: 'environments-table',
						showPagination: envLength > pageSize,
						headers,
					}}
				/>
			)}
			{!!isSmScreen && (
				<Footer>
					<CreateButton
						setOpenCreateModal={setOpenCreateModal}
						isDisabled={disableCreateEhBtn}
						buttonText={buttonText}
					/>
				</Footer>
			)}
			{openCreateModal && (
				<CreateEnvironment
					isOpen={openCreateModal}
					onClose={() => setOpenCreateModal(false)}
					isCreate={true}
					isCm={isCmEnv}
					environment={environments[0]}
				/>
			)}
		</Stack>
	);
};

export const CreateButton: React.FC<{
	setOpenCreateModal: (a: boolean) => void;
	isDisabled: boolean;
	buttonText: string;
}> = ({ setOpenCreateModal, isDisabled, buttonText }) => (
	<Tooltip
		label={noCmEnvTooltipMsg}
		isDisabled={!isDisabled}
		shouldWrapChildren
	>
		<XMCSButton
			{...{
				text: buttonText,
				onClick: () => setOpenCreateModal(true),
				leftIcon: <Icon path={mdiPlus} />,
				isDisabled,
			}}
		/>
	</Tooltip>
);
