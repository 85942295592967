import { useMemo } from 'react';
import { SelectFormControl } from '../../shared-components';
import {
	AVAILABLE_CM_ENVIRONMENT_NAME,
	CM_ENVIRONMENT_ID,
	EnvironmentTypesEnum,
} from '../create-project/helpers';
import { useGetEnvironmentsByQuery } from '../../services/environments';
import {
	FormikValues,
	useCreateEditEnvContext,
} from './hooks/CreateEditEnvContext';
import { editCreateEnv } from '../../../@xmcloud/core/messages/en';
import { useParams } from 'react-router-dom';

const { linkToCm } = editCreateEnv;
const { CM } = EnvironmentTypesEnum;

export const CmEnvironment: React.FC = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const { values, errors, touched, setFieldTouched, setValues, isCreate } =
		useCreateEditEnvContext();

	const { data, fetchNextPage, hasNextPage, isFetching } =
		useGetEnvironmentsByQuery({
			type: CM,
			projectId: projectId!,
		});

	const environmentsOptions = useMemo(() => {
		return (
			data?.map((e) => ({
				label: `${e.projectName} / ${e.name}`,
				value: e.id,
			})) || []
		);
	}, [data]);

	return (
		<SelectFormControl
			{...{
				isInvalid: Boolean(
					errors.cmEnvironmentId && touched.cmEnvironmentId,
				),
				isLoading: isFetching,
				options: environmentsOptions,
				onChange: (e: any) => {
					setValues((prev: FormikValues) => ({
						...prev,
						[CM_ENVIRONMENT_ID]: e.value,
						[AVAILABLE_CM_ENVIRONMENT_NAME]: e.label,
					}));
				},
				label: linkToCm,
				name: CM_ENVIRONMENT_ID,
				currentValue: values.cmEnvironmentId,
				error: errors.cmEnvironmentId,
				onFocus: () => setFieldTouched(CM_ENVIRONMENT_ID, true),
				onMenuScrollToBottom: () => {
					hasNextPage && fetchNextPage();
				},
				isRequired: isCreate,
				pt: 0,
				isDisabled: !isCreate,
			}}
		/>
	);
};
