import { environmentVariable } from '../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';

const envVarsTexts = {
	EnvVarsForm: {
		titles: {
			add: 'Add environment variable',
			edit: 'Edit environment variable',
		},
		labels: {
			name: 'Name',
			value: 'Value',
			secret: 'Secret',
		},
		placeholders: {
			name: 'Enter variable name',
			value: 'Enter variable value',
			secret: 'Set value as encrypted and cannot be viewed after its saved',
			newValue: 'Enter new value',
			secretHelper:
				'A secret variable is encrypted and you cannot view its value',
		},
		buttons: {
			save: 'Save',
			cancel: 'Cancel',
		},
		messages: {
			nameRequired: t(environmentVariable.requiredName),
			valueRequired: t(environmentVariable.requiredValue),
			doubleQuoteNotAllowed: t(environmentVariable.doubleQuoteNotAllowed),

			nameNoSpaces: 'Name cannot contain spaces',
			duplicateName: 'A variable with this name already exists',
			nameInvalidCharacters: t(environmentVariable.invalidCharacters),
		},
	},
	EnvVarsStory: {
		title: 'Custom environment variables (optional)',
		description:
			'(Explanatory text to be rewritten) On top of the out-of-the-box environment variables, you can add additional custom variables here.',
	},
	EnvVarsAddButton: {
		add: 'Add variable',
	},
	EnvVarsTable: {
		columns: {
			name: 'Name',
			value: 'Value',
			secret: 'Secret',
		},
		tooltips: {
			editVariable: 'Edit Variable',
			removeVariable: 'Remove Variable',
		},
		ariaLabels: {
			editVariable: 'Edit Variable',
			removeVariable: 'Remove Variable',
			lockIcon: 'lock-icon',
		},
	},
};

export default envVarsTexts;
