import { Button, ButtonGroup, HStack, Stack } from '@chakra-ui/react';
import { HeadingMd, SelectFormControl, Text } from '../../../shared-components';
import {
	CREATE_EDITING_HOST,
	EH_ENVIRONMENT_NAME,
	IS_BYOC,
	LINK_ENV_TO_DEFAULT_BRANCH,
	resetCmConnectionLevelFields,
	resetEhConnectionLevelFields,
	STARTER_KIT,
	TEMPLATE,
	TEMPLATE_OWNER,
} from '../helpers';
import { firstStep, secondStep } from '../../../../@xmcloud/core/messages/en';
import { FormikValues, useCreateProject } from '../hooks/CreateProjectContext';
import { useEffect } from 'react';

const { useStarterkit, useOwnCode } = secondStep;
const { sourceCodeSetup, starterKit, starterkiSubtitle } = firstStep;

// this is editing host environment name in  xmcloud.build.json file in the XMCLOUD template. this is Next.js starter template
const EH_ENV_NAME_XMCLOUD_TEMPLATE = 'nextjsstarter';

export const SetupTemplate: React.FC<{
	templates: {
		label: string;
		value: string;
		owner: string;
		title: string;
	}[];
	isLoading: boolean;
}> = ({ templates, isLoading }) => {
	const { setValues, setFieldTouched, errors, values, isByoc } =
		useCreateProject();

	useEffect(() => {
		if (templates.length > 0) {
			setValues((prev) => ({
				...prev,
				[TEMPLATE]: templates[0].value,
				[TEMPLATE_OWNER]: templates[0].owner,
				[EH_ENVIRONMENT_NAME]: EH_ENV_NAME_XMCLOUD_TEMPLATE,
				[STARTER_KIT]: templates[0].title,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templates.length]);

	const handleUseTemplateClick = () => {
		setValues((prev) => ({
			...prev,
			[IS_BYOC]: false,
			[LINK_ENV_TO_DEFAULT_BRANCH]: true,
			[CREATE_EDITING_HOST]: false,
			[EH_ENVIRONMENT_NAME]: EH_ENV_NAME_XMCLOUD_TEMPLATE,
			...resetCmConnectionLevelFields,
			...resetEhConnectionLevelFields,
		}));
	};

	const handleUseOwnCodeClick = () => {
		setValues((prev) => ({
			...prev,
			[IS_BYOC]: true,
			[LINK_ENV_TO_DEFAULT_BRANCH]: false,
			[CREATE_EDITING_HOST]: true,
			[EH_ENVIRONMENT_NAME]: '',
			...resetCmConnectionLevelFields,
			...resetEhConnectionLevelFields,
		}));
	};

	return (
		<Stack gap="4">
			<HeadingMd text={sourceCodeSetup} />
			<Text text={starterkiSubtitle} variant="small" maxW="md" />
			<HStack>
				<ButtonGroup
					variant="toggle"
					size="sm"
					layerStyle="toggleGroup"
				>
					<Button
						onClick={handleUseTemplateClick}
						data-testid="use-xmcloud-template-btn"
						isActive={!isByoc}
					>
						{useStarterkit}
					</Button>
					<Button
						onClick={handleUseOwnCodeClick}
						data-testid="use-own-code-btn"
						isActive={isByoc}
					>
						{useOwnCode}
					</Button>
				</ButtonGroup>
			</HStack>
			{!isByoc && (
				<SelectFormControl
					{...{
						isInvalid: Boolean(errors.template),
						isLoading: isLoading,
						onChange: (e: any) => {
							setValues((prev: FormikValues) => ({
								...prev,
								[TEMPLATE]: e.value,
							}));
						},
						label: starterKit,
						name: TEMPLATE,
						currentValue: values.template,
						error: errors.template,
						pt: 2,
						onFocus: () => setFieldTouched(TEMPLATE, true),
						isRequired: true,
						options: templates,
					}}
				/>
			)}
		</Stack>
	);
};
