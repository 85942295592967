import { MenuItem, MenuList, Text } from '@chakra-ui/react';
import {
	HostingProvider,
	IGetHostingInstallation,
} from '../../../models/hostingModel';
import { connections } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { RowActions } from '../../../shared-components';
import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<IGetHostingInstallation>();

const {
	providerName,
	connectionName,
	connectionId,
	createdBy,
	createdAt,
	vercel,
} = connections;

export const useHostingConnectionsTableColumns = (
	setSelectedInstallation: (s: string) => void,
	setDeleteConnectionModal: (v: boolean) => void,
) => {
	return useMemo(
		() => [
			columnHelper.accessor('name', {
				header: () => t(connectionName),
				cell: (info) => info.getValue(),
			}),
			columnHelper.accessor('provider', {
				header: () => t(providerName),
				cell: (info) =>
					info.getValue() === HostingProvider.Vercel
						? vercel
						: info.getValue(),
			}),
			columnHelper.accessor('id', {
				header: () => t(connectionId),
				cell: (info) => info.getValue(),
			}),
			columnHelper.accessor('createdBy', {
				header: () => t(createdBy),
				cell: (info) => (
					<Text noOfLines={1} whiteSpace="wrap">
						{info.getValue()}
					</Text>
				),
			}),
			columnHelper.accessor('createdAt', {
				header: () => t(createdAt),
				cell: (info) => getReadableDate(info.getValue()),
			}),
			columnHelper.display({
				id: 'actions',
				cell: ({
					row: {
						original: { id },
					},
				}) => {
					return (
						<RowActions ariaLabel="connections-hosting-table-action-menu">
							<MenuList>
								<MenuItem
									onClick={() => {
										setSelectedInstallation(id);
										setDeleteConnectionModal(true);
									}}
									minW="3xs"
								>
									{t(connections.delete)}
								</MenuItem>
							</MenuList>
						</RowActions>
					);
				},
				maxSize: 0,
			}),
		],
		[setSelectedInstallation, setDeleteConnectionModal],
	);
};
