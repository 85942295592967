import { useAuth0 } from '@auth0/auth0-react';
import { Link, MenuItem } from '@chakra-ui/react';
import { t } from '@transifex/native';
import IconMenu from '../../../app/shared-components/menu/IconMenu';
import { resetLastSuccessfulLoginOrganizationId } from '../../../app/auth/Auth0Storage';
import config from '../../../app/config/config';
import { useGetCurrentUser } from '../../../app/services/user';
import { app } from '../../core/messages/en';

const UserMenu: React.FC = () => {
	const { logout, user } = useAuth0();
	const { data } = useGetCurrentUser();
	const currentUser = data?.data;

	const avatarSrc = user?.picture;

	const name =
		currentUser?.givenName && currentUser.familyName
			? `${currentUser.givenName} ${currentUser.familyName}`
			: '';

	return (
		<IconMenu name={name} avatarSrc={avatarSrc}>
			<MenuItem
				as={Link}
				isExternal
				href={config.externalLinks.profile_manage}
				boxShadow="none !important"
				textDecoration="none !important"
			>
				{t(app.manageAccount)}
			</MenuItem>
			<MenuItem
				onClick={() => {
					resetLastSuccessfulLoginOrganizationId();
					logout({
						returnTo: window.location.origin,
					});
				}}
			>
				{t(app.logout)}
			</MenuItem>
		</IconMenu>
	);
};

export default UserMenu;
