import { Box, Stack, Switch } from '@chakra-ui/react';
import { useBetaFeatures, useSharedState } from '@xmcloud/hooks';
import { CONTENT_TRANSFER } from '@xmcloud/hooks/context/useSharedState';
import { useFeature } from 'app/feature-flag/features';
import { CardBox, Text } from 'app/shared-components';

export const BetaFeaturesPage: React.FC = () => {
	const createProjectV3 = useFeature('CreateProjectV3');
	const contentTransfer = useFeature('ContentTransfer');

	const {
		createProjectV3BetaFeature,
		setCreateProjectV3BetaFeature,
		contentTransferExpFeat,
		setContentTransferExpFeature,
	} = useBetaFeatures();
	const { setState: setContentTransferEnabled } = useSharedState(
		CONTENT_TRANSFER,
		contentTransferExpFeat,
	);
	const noBetaFeatures = !createProjectV3 && !contentTransfer;

	if (noBetaFeatures)
		return (
			<Box
				width="full"
				alignItems="center"
				display="flex"
				justifyContent="center"
				pt="5%"
			>
				<Text fontWeight="semibold" text="No beta features found" />
			</Box>
		);

	return (
		<Stack spacing="4">
			{createProjectV3 && (
				<CardBox
					title="Now you can use different repositories for your Authoring environments and Editing hosts"
					description="Deploy your authoring environments and editing hosts separately. This feature introduces changes that improve the structure of new projects. New projects can also have a different source control account and repository for each environment. Projects created before you enable this feature are not affected and will continue to work as they did before."
				>
					<Switch
						isChecked={createProjectV3BetaFeature}
						onChange={(e) => {
							setCreateProjectV3BetaFeature(e.target.checked);
						}}
					/>
				</CardBox>
			)}
			{contentTransfer && (
				<CardBox
					title="Content Transfer"
					description="Transfer content between instances"
				>
					<Switch
						isChecked={contentTransferExpFeat}
						onChange={(e) => {
							setContentTransferExpFeature(e.target.checked);
							setContentTransferEnabled(e.target.checked);
						}}
					/>
				</CardBox>
			)}
		</Stack>
	);
};
