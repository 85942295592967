import { AzureFeatureFlagValue } from '@xmcloud/hooks/context/azureFeatureFlags';
import { QueryResponse } from '@xmcloud/types';
import config from 'app/config/config';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

/**
 * Custom hook to manage new feature announcements.
 *
 * This hook fetches feature flags from the query client, filters and transforms them
 * to identify new feature announcements, and manages the state of the announcement modal.
 *  Example of a new feature announcement flag
{
	"id": "NewFeatureAnnouncement_NAME_NEW_FEATURE",// it is necessary to prefix the id with 'NewFeatureAnnouncement' for feature announcement flags
	"description": "[Description]",
	"enabled": boolean,
	"conditions": {
		"client_filters": [
			{
				"name": "same as id",
				"parameters": {
					"title": "write modal title here",
					"description": "write modal description here"
					"iconLink": "type icon link here",
					"learnMoreLink": "type learn more link here, ie documentation link"
				}
			}
		]
	}
}
 */

export const useNewAnnouncedFeatures = () => {
	const [isOpen, setIsOpen] = useState(false);
	const queryClient = useQueryClient();
	const data = queryClient.getQueryData([
		config.featureFlags.scope,
	]) as QueryResponse<AzureFeatureFlagValue[]>;
	const newFeatureAnnouncement = useRef(false);

	const announcedFeatures = useMemo(
		() => transformNewAnnouncedFeatures(data?.data ?? []),
		[data],
	);

	const pendingFeatures = useRef([...announcedFeatures]);

	const pendingFirstFeature = pendingFeatures.current[0];
	const featureId = pendingFirstFeature?.id || '';

	useEffect(() => {
		if (!newFeatureAnnouncement.current && featureId) {
			const isAnnounced = localStorage.getItem(featureId) === 'true';
			if (isAnnounced) {
				pendingFeatures.current = pendingFeatures.current.filter(
					(f) => f.id !== featureId,
				);
			}
		}
	}, [featureId]);

	useEffect(() => {
		newFeatureAnnouncement.current =
			localStorage.getItem(featureId) === 'true';
		if (!newFeatureAnnouncement.current && featureId) {
			setIsOpen(true);
		}
	}, [featureId]);

	const onClose = () => {
		setIsOpen(false);
		localStorage.setItem(featureId, 'true');
		pendingFeatures.current.shift();
	};

	const title = pendingFirstFeature?.title as string;
	const description = pendingFirstFeature?.description as string;
	const iconLink = pendingFirstFeature?.iconLink as string;
	const learnMoreLink = pendingFirstFeature?.learnMoreLink as string;

	return {
		onClose,
		isOpen,
		pendingFirstFeature,
		newFeatureAnnouncement: newFeatureAnnouncement.current,
		title: title || '',
		description: description || '',
		iconLink: iconLink || '',
		learnMoreLink: learnMoreLink || '',
	};
};

export const transformNewAnnouncedFeatures = (
	flags: AzureFeatureFlagValue[],
) => {
	// it is necessary to prefix the id with 'NewFeatureAnnouncement' for feature announcement flags
	const filteredFeatures = flags.filter(
		(f) => f.id?.startsWith('NewFeatureAnnouncement') && f.enabled,
	);

	return (
		filteredFeatures.map((f) => ({
			id: f.id,
			enabled: f.enabled,
			title: f?.conditions?.client_filters?.[0]?.parameters?.title,
			description:
				f?.conditions?.client_filters?.[0]?.parameters?.description,
			iconLink: f?.conditions?.client_filters?.[0]?.parameters?.iconLink,
			learnMoreLink:
				f?.conditions?.client_filters?.[0]?.parameters?.learnMoreLink,
		})) || []
	);
};
