import { MenuItem, MenuList, Stack, Text, Wrap } from '@chakra-ui/react';
import {
	mdiFeather,
	mdiLinkVariant,
	mdiMonitor,
	mdiOpenInNew,
	mdiSourceBranch,
} from '@mdi/js';
import { createColumnHelper } from '@tanstack/react-table';
import { t } from '@transifex/native';
import { useRef, useState } from 'react';
import { app, environments } from '../../../../@xmcloud/core/messages/en';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { handlePush, handleTo } from '../../../../@xmcloud/utils/helpers';
import config from '../../../config/config';
import { IGetEnvironmentResponseV2 } from '../../../models/environmentModel';
import { useDeploymentCreateAndDeploy } from '../../../services/deploymentCreateDeploy';
import { Icon, IconText, Link, RowActions } from '../../../shared-components';
import DeleteEnvironment from '../components/DeleteEnvironment';
import RestartMenuItem from '../components/RestartMenuItem';
import { useGetEnvType } from '../hooks/useGetEnvType';
import { EnvironmentTypesEnum } from 'app/pages/create-project/helpers';

const { autoDeploy, buildAndDeploy, variables, openDashboard } = environments;
const { name, id, delete: deletetxt } = app;

const {
	deployments: deploymentsPath,
	oldDeployments: oldDeploymentsPath,
	deploymentLogs: deploymentLogsPath,
	oldDeploymentLogs: oldDeploymentLogsPath,
	variables: variablesPath,
	oldVariables: oldVariablesPath,
} = goTo;

const { xm_cloud_dashboard } = config.externalLinks;
const { EH } = EnvironmentTypesEnum;

const columnHelper = createColumnHelper<IGetEnvironmentResponseV2>();

export const useEnvironmentsTableColumns = (environmentType?: string) => {
	const [editModal, setEditModal] = useState(false);
	const environmenRef = useRef({} as IGetEnvironmentResponseV2);
	const { isEhEnv, isLegacyEnvsRoute } = useGetEnvType();

	const [deploymentCreateAndDeploy, loading] = useDeploymentCreateAndDeploy();

	return [
		...(!isLegacyEnvsRoute
			? [
					columnHelper.accessor('type', {
						header: () => t('Type'),
						cell: () => (
							<Icon
								path={isEhEnv ? mdiFeather : mdiMonitor}
								color={isEhEnv ? 'purple' : 'cyan'}
								layerStyle="icon.subtle"
								boxSize="icon.2xs"
							/>
						),
						enableSorting: false,
						maxSize: 0,
					}),
				]
			: []),
		columnHelper.accessor('name', {
			header: () => t(name),
			maxSize: 96,
			cell: (environment) => {
				const {
					id: environmentId,
					name,
					type: environmentType,
					projectId,
					repositoryBranch,
					deployOnCommit,
				} = environment.row.original;

				const func = environmentType ? 'details' : 'oldDeployments';

				return (
					<Stack>
						<Link
							text={name}
							to={handleTo(
								goTo[func](
									projectId,
									environmentId,
									environmentType,
								),
							)}
							whiteSpace="wrap"
							noOfLines={2}
							maxW="lg"
						/>
						<Wrap spacingX="3" spacingY="1">
							{repositoryBranch ? (
								<IconText
									IconName={mdiLinkVariant}
									text={repositoryBranch}
									iconColor="neutral"
									iconSize={4}
									fontSize="sm"
									textColor="chakra-subtle-text"
									noOfLines={2}
								/>
							) : null}
							{deployOnCommit ? (
								<IconText
									IconName={mdiSourceBranch}
									text={autoDeploy}
									iconColor="neutral"
									iconSize={4}
									fontSize="sm"
									textColor="chakra-subtle-text"
									whiteSpace="wrap"
								/>
							) : null}
						</Wrap>
					</Stack>
				);
			},
		}),
		columnHelper.accessor('id', {
			header: () => t(id),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('lastUpdatedAt', {
			header: () => 'Date created',
			cell: (info) => getReadableDate(info.renderValue() as string),
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original } }) => (
				<>
					<RowActions
						ariaLabel="environments-table-action-menu"
						isLoading={
							loading &&
							environmenRef.current?.id === original?.id
						}
						data-testid="environments-table-action-menu"
					>
						<MenuList>
							<MenuItem
								data-behavior-analytics-id="build-and-deploy-button"
								data-testid="build-and-deploy-button"
								onClick={() => {
									environmenRef.current = original;
									const environment = original;
									deploymentCreateAndDeploy({
										environmentId: environment?.id,
										onSuccess: (
											deploymentId: string,
											data,
										) => {
											if (data?.data) {
												handlePush(
													environment?.type
														? deploymentsPath(
																environment?.projectId,
																environment?.id,
																environment?.type,
															)
														: oldDeploymentsPath(
																environment?.projectId,
																environment?.id,
															),
												);

												return;
											}
											handlePush(
												environment?.type
													? deploymentLogsPath(
															environment?.projectId,
															environment?.id,
															deploymentId,
															environment?.type,
														)
													: oldDeploymentLogsPath(
															environment?.projectId,
															environment?.id,
															deploymentId,
														),
											);
										},
									});
								}}
							>
								{t(buildAndDeploy)}
							</MenuItem>
							<MenuItem
								onClick={() => {
									const environment = original;
									handlePush(
										environment?.type
											? variablesPath(
													environment?.projectId,
													environment?.id,
													environment?.type,
												)
											: oldVariablesPath(
													environment?.projectId,
													environment?.id,
												),
									);
								}}
							>
								{t(variables)}
							</MenuItem>
							<MenuItem
								onClick={() => {
									const environment = original;
									environmenRef.current = environment;
									setEditModal(true);
								}}
							>
								{t(deletetxt)}
							</MenuItem>
							{environmentType !== EH && (
								<MenuItem
									onClick={() => {
										const environment = original;
										window.open(
											xm_cloud_dashboard(
												environment.host,
											),
										);
									}}
									justifyContent="space-between"
									_hover={{
										'.new-window-icon': {
											opacity: 1,
											transition: '0.7s',
										},
									}}
								>
									<Text>{t(openDashboard)}</Text>
									<Icon
										path={mdiOpenInNew}
										boxSize={5}
										className="new-window-icon"
										opacity={0}
										color="chakra-placeholder-color"
									/>
								</MenuItem>
							)}
							{environmentType !== EH && (
								<RestartMenuItem
									environmentId={original.id}
									key={original.id}
								/>
							)}
						</MenuList>
					</RowActions>
					{editModal && (
						<DeleteEnvironment
							{...{
								openModal: editModal,
								setOpenModal: setEditModal,
								environmentDetails: environmenRef.current,
							}}
						/>
					)}
				</>
			),
			maxSize: 0,
		}),
	];
};
